import React from 'react'
import _ from 'lodash'

import { Button } from 'ui/components'

import { serviceIdToName } from 'utils/functions'

const TYPES = {
  APPROVED: ['WHITELIST', 'UNCLASSIFIED'],
  REPROVED: ['BLOCKLIST'],
  PROCESSING: ['WHITELIST', 'UNCLASSIFIED', 'BLOCKLIST'],
}

export default ({ cpfDoc, planServicesList }) => {
  const [detailOpen, setDetailOpen] = React.useState(false)

  const motiveTypes = TYPES[cpfDoc.analysis_status || cpfDoc.status]
  const serviceInfos = _.filter(
    cpfDoc.service_infos,
    (x) => !_.isEmpty(x.motive) && !_.isEmpty(motiveTypes) && motiveTypes.includes(x.motive.list_type),
  )

  const renderNumber = (number) => {
    return (
      <tr>
        <td>Número:</td>
        <td>{number}</td>
      </tr>
    )
  }

  const renderKeywords = (keywords) => {
    if (_.isEmpty(keywords)) return null

    return (
      <tr>
        <td>Keywords encontradas:</td>
        <td>{keywords.join(', ')}</td>
      </tr>
    )
  }

  const renderTexts = (texts) => {
    const newTexts = _.map(texts, (text, i2) => <p key={i2.toString()}>{text}</p>)

    return (
      <tr style={{ verticalAlign: 'top' }}>
        <td>Tipos do processo:</td>
        <td>{newTexts}</td>
      </tr>
    )
  }

  const renderMotiveData = (motiveData) => {
    return (
      <table>
        <tbody>
          {renderNumber(motiveData.number)}
          {renderKeywords(motiveData.keywords)}
          {renderTexts(motiveData.texts)}
        </tbody>
      </table>
    )
  }

  const renderMotives = (motives) => {
    const motiveDatas = _.map(motives, (motive) => motive.datas).flat()

    return _.map(motiveDatas, (motiveData, i) => (
      <ul key={i.toString()}>
        <li>
          {renderMotiveData(motiveData)}
        </li>
      </ul>
    ))
  }

  const handleToogleDetail = () => {
    setDetailOpen(!detailOpen)
  }

  return (
    <div style={{ marginBottom: 20 }}>
      <Button onClick={handleToogleDetail}>
        {`${detailOpen ? 'Esconder' : 'Ver'} Motivos`}
      </Button>

      <ul style={{ display: detailOpen ? null : 'none' }}>
        {_.map(serviceInfos, (serviceInfo) => {
          const { motive } = serviceInfo

          return (
            <li key={serviceInfo.data_id}>
              <strong>{serviceIdToName(planServicesList, serviceInfo.service_id)}</strong>
              <p>{motive.list_type}</p>
              {renderMotives(motive.motives)}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
