import React from 'react'
import * as Sentry from '@sentry/browser'
import { formatToCEP } from 'brazilian-values'

import ShowInMapButton from 'ui/components/ShowInMap'

export const columnsAddress = [
  {
    name: 'address',
    label: 'Endereço',
  },
  {
    name: 'neighborhood',
    label: 'Bairro',
  },
  {
    name: 'city_state',
    label: 'Cidade/Estado',
  },
  {
    name: 'zipcode',
    label: 'CEP',
    options: {
      customBodyRender: (value) => {
        try {
          return value ? formatToCEP(value) : null
        } catch (err) {
          Sentry.captureException(err)
          return false
        }
      },
    },
  },
  {
    name: 'map',
    label: 'Ver no Mapa',
    options: {
      setCellHeaderProps: () => { return { style: { display:'flex', justifyContent:'center' } } },
      setCellProps: () => { return { style: { display:'flex', justifyContent:'center' } } },
      customBodyRender: (value) => (
        <span style={{ display:'flex', justifyContent:'center' }}>
          <ShowInMapButton address={value.address} zipcode={value.zipcode} cityState={value.cityState} />
        </span>
      ),
    },
  },
]

export const columnsVehicles = [
  {
    name: 'name',
    label: 'Nome',
  },
  {
    name: 'license_plate',
    label: 'Placa',
  },
  {
    name: 'year',
    label: 'Ano',
  },
  {
    name: 'renavam',
    label: 'Renavam',
  },
  {
    name: 'chassi',
    label: 'Chassi',
  },
]
