import React from 'react'
import _ from 'lodash'

import { Button, Modal } from 'ui/components'
import DataTable from 'ui/components/ContainerCommonPanels/DataTable'

import { formatDate } from 'utils/masks'
import { getAnalysisStatusMoveToName } from 'utils/constants/analysisStatus'

const columnsHistoric = [
  {
    name: 'old_analysis_status',
    label: 'Antigo Status',
    options: {
      customBodyRender: (value) => getAnalysisStatusMoveToName(value),
    },
  },
  {
    name: 'new_analysis_status',
    label: 'Novo Status',
    options: {
      customBodyRender: (value) => getAnalysisStatusMoveToName(value),
    },
  },
  {
    name: 'user_email',
    label: 'Alterado por',
  },
  {
    name: 'created_at',
    label: 'Data da alteração',
    options: {
      customBodyRender: (value) => formatDate(value),
    },
  },
  {
    name: 'description',
    label: 'Descrição',
  },
]

export default ({ historic }) => {
  const [modalOpen, setModalOpen] = React.useState(false)

  const handleOpenModal = () => {
    setModalOpen(!modalOpen)
  }

  const renderHistoric = () => {
    return (
      <DataTable
        data={historic}
        columns={columnsHistoric}
      />
    )
  }

  if (_.isEmpty(historic)) return null

  return (
    <div>
      <Button size="small" onClick={handleOpenModal}>
        Ver Alterações de Status
      </Button>

      <Modal
        open={modalOpen}
        onClose={handleOpenModal}
        title="Histórico de Alteração de Status"
      >
        {renderHistoric()}
      </Modal>
    </div>
  )
}
