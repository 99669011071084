import React from 'react'
import * as Sentry from '@sentry/browser'
import { formatToCEP } from 'brazilian-values'

import ShowInMapButton from 'ui/components/ShowInMap'
import { PANEL_ENVS } from 'utils/constants/environments'

import AnalysisButton from './AnalysisButton'

export const columnsCnaes = [
  {
    name: 'number',
    label: 'Número',
  },
  {
    name: 'description',
    label: 'Descrição da Atividade',
  },
]

export const columnsMembershipBoard = (analysisParams) => {
  return (
    [
      {
        name: 'name',
        label: 'Nome',
      },
      {
        name: 'qualification',
        label: 'Qualificação',
      },
      {
        name: 'doc_number',
        label: 'CPF/CNPJ',
      },
      {
        name: 'start_date',
        label: 'Data de Associação',
      },
      {
        name: 'doc_type',
        options: { display: false },
      },
      {
        name: 'create_analysis',
        label: 'Solicitar Análise',
        options: {
          display: analysisParams.displayAnalysisButton,
          customBodyRender: (_value, tableMeta, _updateValue) => {
            const docNumber = tableMeta.rowData[2]
            const docType = tableMeta.rowData[4]
            let planId = null

            switch (docType) {
              case PANEL_ENVS.CPF:
                planId = analysisParams.cpfPlanId
                break
              case PANEL_ENVS.CNPJ:
                planId = analysisParams.cnpjPlanId
                break
              default: return null
            }
            return (
              <AnalysisButton
                analysisParams={{
                  ...analysisParams,
                  planId: planId,
                  docType: docType,
                  docNumber: docNumber,
                }}
              />
            )
          },
        },
      },
    ])
}

export const columnsLegalNature = [
  {
    name: 'number',
    label: 'Número',
  },
  {
    name: 'description',
    label: 'Descrição',
  },
]

export const columnsAddress = [
  {
    name: 'address',
    label: 'Logradouro',
  },
  {
    name: 'neighborhood',
    label: 'Bairro',
  },
  {
    name: 'city_state',
    label: 'Cidade/Estado',
  },
  {
    name: 'zipcode',
    label: 'CEP',
    options: {
      customBodyRender: (value) => {
        try {
          return value ? formatToCEP(value) : null
        } catch (err) {
          Sentry.captureException(err)
          return false
        }
      },
    },
  },
  {
    name: 'map',
    label: 'Ver no Mapa',
    options: {
      setCellHeaderProps: () => { return { style: { display:'flex', justifyContent:'center' } } },
      setCellProps: () => { return { style: { display:'flex', justifyContent:'center' } } },
      customBodyRender: (value) => (
        <span style={{ display:'flex', justifyContent:'center' }}>
          <ShowInMapButton address={value.address} zipcode={value.zipcode} cityState={value.cityState} />
        </span>
      ),
    },
  },
]
