import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Grid } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import {
  Checkbox,
  ExpansionPanel,
  Tooltip,
} from 'ui/components'

import analysisStatusConstants from 'utils/constants/analysisStatus'
import serviceStatusConstants from 'utils/constants/serviceStatus'

const STATUS_CHECKS = [
  {
    label: 'Processando',
    status: serviceStatusConstants.SERVICE_STATUS.processing,
  },
  {
    label: 'Aprovados',
    status: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.approved,
  },
  {
    label: 'Reprovados',
    status: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.reproved,
  },
]

export default ({ onChange }) => {
  const [withColumnAnalysisStatusHistoric, setWithColumnAnalysisStatusHistoric] = useState(false)
  const [withColumnDataCheckbox, setWithColumnDataCheckbox] = useState(false)
  const [withColumnUserEmail, setWithColumnUserEmail] = useState(true)
  const [withRecursiveConsultsCheckbox, setWithRecursiveConsultsCheckbox] = useState(true)
  const [withServicesCheckbox, setWithServicesCheckbox] = useState(false)
  const [withOnlyPepsCheckbox, setWithOnlyPepsCheckbox] = useState(false)
  const [withStatusCheckbox, setWithStatusCheckbox] = useState(_.map(STATUS_CHECKS, 'status'))

  const [showRequiredWarning, setShowRequiredWarning] = useState(false)

  const [withColumnAnalysisExpirationDate, setWithColumnAnalysisExpirationDate] = useState(false)
  const [withColumnsManualAnalysis, setWithColumnsManualAnalysis] = useState(false)
  const [withOnlyManualAnalysis, setWithOnlyManualAnalysis] = useState(false)
  const [withUnusedServicesCheckbox, setWithUnusedServicesCheckbox] = useState(false)

  useEffect(() => {
    onChange(
      {
        // with_column_analysis_status_historic: withColumnAnalysisStatusHistoric, desativado pois nao existe essa funcionalidade nesse painel
        with_column_data: withColumnDataCheckbox,
        with_column_user_email: withColumnUserEmail,
        with_recursive_consults: withRecursiveConsultsCheckbox,
        with_only_peps: withOnlyPepsCheckbox,
        with_services: withServicesCheckbox,
        with_status: withStatusCheckbox,

        with_column_analysis_expiration_date: withColumnAnalysisExpirationDate,
        with_columns_manual_analysis: withColumnsManualAnalysis,
        with_only_manual_analysis: withOnlyManualAnalysis,
        with_unused_services: withUnusedServicesCheckbox,
      },
      showRequiredWarning,
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    withColumnAnalysisStatusHistoric,
    withColumnDataCheckbox,
    withColumnUserEmail,
    withRecursiveConsultsCheckbox,
    withOnlyPepsCheckbox,
    withServicesCheckbox,
    withStatusCheckbox,

    withColumnAnalysisExpirationDate,
    withColumnsManualAnalysis,
    withOnlyManualAnalysis,
    withUnusedServicesCheckbox,
  ])

  const setWithColumnDataCheckboxes = (checked) => {
    if (checked === true) setWithServicesCheckbox(checked)
    setWithColumnDataCheckbox(checked)
  }

  const setWithServicesCheckboxes = (checked) => {
    if (checked === false) setWithColumnDataCheckbox(checked)
    setWithServicesCheckbox(checked)
  }

  const checkBoxesStateFunctions = {
    with_column_analysis_status_historic: setWithColumnAnalysisStatusHistoric,
    with_column_data: setWithColumnDataCheckboxes,
    with_column_user_email: setWithColumnUserEmail,
    with_recursive_consults: setWithRecursiveConsultsCheckbox,
    with_only_peps: setWithOnlyPepsCheckbox,
    with_services: setWithServicesCheckboxes,
    with_status: setWithStatusCheckbox,

    with_column_analysis_expiration_date: setWithColumnAnalysisExpirationDate,
    with_columns_manual_analysis: setWithColumnsManualAnalysis,
    with_only_manual_analysis: setWithOnlyManualAnalysis,
    with_unused_services: setWithUnusedServicesCheckbox,
  }

  const handleCheckBooleanFilter = (filterKey, checked) => {
    checkBoxesStateFunctions[filterKey](checked)
  }

  const handleCheckArrayFilter = (filterKey, checked, item) => {
    let newValues = null
    if (checked) newValues = _.union(withStatusCheckbox, [item])
    else newValues = _.without(withStatusCheckbox, item)

    checkBoxesStateFunctions[filterKey](newValues)

    const invalid = newValues.length === 0
    setShowRequiredWarning(invalid)
  }

  const withStatusIncludes = (status) => (
    withStatusCheckbox.includes(status)
  )

  const renderCheckboxWithStatus = () => {
    return (
      <React.Fragment>
        {_.map(STATUS_CHECKS, (type) => (
          <Checkbox
            key={type.status}
            label={type.label}
            value={withStatusIncludes(type.status)}
            onClick={(checked) => handleCheckArrayFilter('with_status', checked, type.status)}
          />
        ))}
      </React.Fragment>
    )
  }

  return (
    <div>
      <ExpansionPanel title="Configurações de layout" size="small">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <p>Incluir Colunas</p>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Checkbox
              label="Coluna com email do usuário"
              value={withColumnUserEmail}
              onClick={(checked) => handleCheckBooleanFilter('with_column_user_email', checked)}
            />

            <Checkbox
              label="Coluna com data de validade"
              value={withColumnAnalysisExpirationDate}
              onClick={(checked) => handleCheckBooleanFilter('with_column_analysis_expiration_date', checked)}
            />

            {/* BV: desativado pois nao existe essa funcionalidade nesse painel
            <Checkbox
              label="Coluna das alterações de status"
              value={withColumnAnalysisStatusHistoric}
              onClick={(checked) => handleCheckBooleanFilter('with_column_analysis_status_historic', checked)}
            />
            */}

            <Checkbox
              label="Colunas dos serviços"
              value={withServicesCheckbox}
              onClick={(checked) => handleCheckBooleanFilter('with_services', checked)}
            />
            <div style={{ marginLeft: 15 }}>
              <Checkbox
                label='Coluna "dados" dos serviços'
                value={withColumnDataCheckbox}
                onClick={(checked) => handleCheckBooleanFilter('with_column_data', checked)}
              />
            </div>

            <Checkbox
              label="Colunas com informações das análises manuais"
              value={withColumnsManualAnalysis}
              onClick={(checked) => handleCheckBooleanFilter('with_columns_manual_analysis', checked)}
            />
          </Grid>
        </Grid>
      </ExpansionPanel>

      <ExpansionPanel title="Filtros" size="small">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <p>Incluir Consultas</p>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Checkbox
              label="Apenas consultas que foram para a análise manual"
              value={withOnlyManualAnalysis}
              onClick={(checked) => handleCheckBooleanFilter('with_only_manual_analysis', checked)}
            />

            <Grid container item xs={12}>
              <Grid item xs={10}>
                <Checkbox
                  label="Mostrar consultas feitas via QSA Recursivo"
                  value={withRecursiveConsultsCheckbox}
                  onClick={(checked) => handleCheckBooleanFilter('with_recursive_consults', checked)}
                />
              </Grid>
              <Grid item xs={2}>
                <Tooltip
                  leaveDelay={800}
                  title={(
                    <div>
                      <h3 color="inherit">Consultas via QSA Recursivo</h3>
                      <p>
                        Exibe as consultas que foram criadas pelo serviço QSA Recursivo.
                      </p>
                      <p>
                        Mostrará a coluna &quot;Sócio do CNPJ&quot;, onde terá o número do CNPJ que a consulta é sócia.
                      </p>
                    </div>
                  )}
                >
                  <HelpOutlineIcon />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4}>
            <p>Incluir Serviços</p>
          </Grid>
          <Grid item xs={12} sm={8}>
            {/* BV Onepld: desativado pois nao existe essa funcionalidade nesse painel
            <Checkbox
              label="Apenas resultados PEP"
              value={withOnlyPepsCheckbox}
              onClick={(checked) => handleCheckBooleanFilter('with_only_peps', checked)}
            />
            */}
            <Grid container item xs={12}>
              <Grid item xs={10}>
                <Checkbox
                  label="Incluir serviços desconsiderados"
                  value={withUnusedServicesCheckbox}
                  onClick={(checked) => handleCheckBooleanFilter('with_unused_services', checked)}
                />
              </Grid>
              <Grid item xs={2}>
                <Tooltip
                  leaveDelay={800}
                  title={(
                    <React.Fragment>
                      <h3 color="inherit">Serviços desconsiderados</h3>
                      <div>
                        <p>
                          Um serviço é classificado como desconsiderado quando seu valor não é levado em conta, pois com os valores dos outros serviços já é possível decidir o status final da consulta (aprovado ou reprovado).
                        </p>
                        <p>
                          Será exibido no lugar dos dados do serviço a palavra
                          <strong> DESCONSIDERADO</strong>
                          .
                        </p>
                      </div>
                    </React.Fragment>
                  )}
                >
                  <HelpOutlineIcon />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4}>
            <p>Incluir Status</p>
          </Grid>
          <Grid item xs={12} sm={8}>
            {renderCheckboxWithStatus()}
            {showRequiredWarning && (<p style={{ color: 'red', fontWeight: 'bold' }}>Selecione ao menos uma opção</p>)}
          </Grid>
        </Grid>
      </ExpansionPanel>
    </div>
  )
}
