import React from 'react'
import _ from 'lodash'

import RoomIcon from '@material-ui/icons/Room'
import { IconButton } from '@material-ui/core'
import { formatToCEP } from 'brazilian-values'

import { Tooltip } from 'ui/components'

const ShowInMapButton = ({ address, zipcode, cityState }) => {
  const openMap = () => {
    const formatZipCode = () => (
      _.isEmpty(zipcode) ? '' : formatToCEP(zipcode)
    )

    const query = [address, cityState, formatZipCode()].filter(Boolean).join(', ')
    const url =  `http://maps.google.com/maps?q=${query}`
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <Tooltip title="Ver no Mapa">
      <IconButton style={{ border: '1px solid #ddd' }} onClick={openMap}>
        <RoomIcon htmlColor="#ed524e" fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}

export default ShowInMapButton
